import React from "react";
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from '@react-oauth/google';

import { forwardTo } from "./../helpers";

export const SocialLogin = ({ loginGoogle, loginFacebook, entity }) => {
  const { t } = useTranslation();
  const {AliasAPP} = entity;

  const signIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      loginGoogle({token: tokenResponse.access_token});
    },
    onError: (error) => {
      console.error('Error de login con Google', error);
    },
  });

  const handleClick = () => {
    signIn(); // Llamamos a la función 'signIn' proporcionada por el hook
  };

  return (
    <React.Fragment>
      <Col s={12} m={12}>
        <button
          className="waves-effect loginBtn loginBtn--gecor"
          onClick={() => forwardTo("/register")}
        >
          {t("social.p1", {entityName: AliasAPP?AliasAPP:"Gecor"})}
        </button>
      </Col>
      <Col s={12} m={12}>
        <button
          type="submit"
          className="waves-effect loginBtn loginBtn--google"
          onClick={handleClick}
        >
          {t("social.p2")}
        </button>
      </Col>
      {/*<Col s={12} m={12}>
        <button
          type="submit"
          className="waves-effect loginBtn loginBtn--facebook"
          onClick={loginFacebook}
        >
          {t("social.p3")}
        </button>
  </Col>*/}
    </React.Fragment>
  );
};
