import React from "react";
// import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withFormik } from "formik";
import * as Yup from "yup";
import "./NewIncidentPage.css";
import { DynamicBackground } from "./../../components";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Files from "react-butterfiles";
import Badge from 'react-bootstrap/Badge'
import { css } from "emotion";
import { withTranslation } from "react-i18next";

import { LocationSearchInput } from "./LocationSearchInput";

import { filteredElements, filteredIncidentsType } from "../../reducers/incidents.reducer";
import {
  filteredStreets,
  filteredBuildings
} from "../../reducers/entities.reducer";
import { incidentsActions, entityActions } from "../../actions";
import { forwardTo } from "../../helpers/forward";
import { isWebpSupported } from "react-image-webp/dist/utils";

import { defaultAyuntamientoID } from '../../constants';

/* ICONS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/* ICONS */

import Confirm from "./Confirm";
import LocationBuildingEngine from "./LocationBuildingEngine";
import { TipologySearch } from "../../components/TipologySearch/TipologySearch";

const gallery = css({
  width: "auto",
  height: "auto",
  minHeight: 155,
  border: "1px #1b9696 dashed",
  "&.dragging": {
    backgroundColor: "#80808008"
  },
  ul: {
    padding: 10,
    margin: 0
  },
  li: {
    margin: 2,
    cursor: "pointer",
    border: "1px #1b9696 solid",
    width: 128,
    height: 128,
    overflow: "hidden",
    display: "inline-block",
    position: "relative",
    float: "left",
    "&.new-image": {
      textAlign: "center",
      color: "#1b9696",
      div: {
        fontSize: 30,
        marginTop: 40
      }
    },
    img: {
      maxWidth: "100%"
    }
  }
});

class NewIncidentForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      files: [],
      errors: [],
      dragging: false,
      open: false,
      isSubmitting: false,
      tipologies: []
    };

    this.onHandleSearchStreet = this.onHandleSearchStreet.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newIncident) {
      this.setState({ open: true });
    }
  }

  handleChangeEntity(e) {
    e.preventDefault();
    this.props.changeEnttity();
  }

  handleChangeFamilyType(familyType) {
    this.props.dispatch(incidentsActions.changeFamilyType(familyType));    
  }

  handleChangeElementType(elementType) {
    this.props.dispatch(incidentsActions.changeElementType(elementType));
  }

  handleChangeIncidentType(incidentType) {
    this.props.dispatch(incidentsActions.changeIncidentType(incidentType));
  }

  handleChangeUnitType(unitType) {
    this.props.dispatch(incidentsActions.changeUnitType(unitType));
  }

  handleChangePromotionType(promotionType) {
    this.props.dispatch(incidentsActions.changePromotionType(promotionType));
  }

  handleModalClose() {
    this.props.dispatch(incidentsActions.newIncidentReset());
    forwardTo("/incidents");
  }

  handleNearbyStreets(latLng) {
    this.props.dispatch(incidentsActions.getNearbyStreets(latLng));
  }

  onHandleSearchStreet(streetQuery, value) {
    if (value) {
      const { setFieldValue } = this.props;
      setFieldValue("streetName", value);
    }
    if (defaultAyuntamientoID == 1) {
      this.props.dispatch(entityActions.searchExternalStreet(streetQuery));
    } else {
      this.props.dispatch(entityActions.searchStreet(streetQuery));
    }
  }



  render() {
    const { isSubmitting } = this.state;
    const {
      elements,
      incidentsTypes,
      unitsTypes,
      promotionsTypes,
      newIncident,
      families,
      buildings,
      values,
      touched,
      errors,
      validateForm,
      // dirty,
      handleChange,
      setFieldValue,
      // setSubmitting,
      // isSubmitting,
      resetForm,
      handleSubmit,
      isValid,
      tipologies,
      t
    } = this.props;


    const _handleSelectedTipology = tipology => {
      const { familiaID, elementoID, incidenciaID } = tipology;
      const { setFieldValue } = this.props;
      let familyType; 
      let elementType; 
      let incidentType;

      if (familiaID > 0) {
        const family = families.filter(
          family => family.FamiliasTiposElementosID == familiaID
        );
     
        if (family.length > 0) {
          const { Nombre } = family[0];
          setFieldValue("familyTypeValue", Nombre);
        }
        familyType = familiaID;
        setFieldValue("familyType", familiaID);
        // this.handleChangeFamilyType(familiaID);
        
        if (elementoID > 0) {
          const element = tipologies.Elementos.filter(
            element => element.TipoElementoID == elementoID && element.FamiliaTipoElementoID == familiaID
          );
          
          if (element.length > 0) {
            const { DesTipoElemento } = element[0];
            setFieldValue("elementTypeValue", DesTipoElemento);
          }
          elementType = elementoID;
          setFieldValue("elementType", elementoID);
          // this.handleChangeElementType(elementoID);
          if (incidenciaID > 0) {
            const incident = tipologies.Incidencias.filter(
              incidentType => incidentType.TipoIncID == incidenciaID && incidentType.TipoElementoID == elementoID
            );
            if (incident.length > 0) {
              const { TipoInc } = incident[0];
              setFieldValue("incidentTypeValue", TipoInc);
              incidentType = incidenciaID;
              setFieldValue("incidentType", incidenciaID);
            }else {
              setFieldValue("incidentType", "");
              setFieldValue("incidentTypeValue", "");
            }
          } else {
            setFieldValue("incidentType", "");
            setFieldValue("incidentTypeValue", "");
          }
        }else{
          setFieldValue("elementType", "");
          setFieldValue("elementTypeValue", "");
          setFieldValue("incidentType", "");
          setFieldValue("incidentTypeValue", "");
        }
      }
      
      this.props.dispatch(incidentsActions.changeAllType(familyType, elementType, incidentType));
    };

    const _handleChangeElementType = elementType => {
      const { currentTarget } = elementType;
      const { value } = currentTarget;

      const element = elements.filter(
        element => element.TipoElementoID == value
      );
      if (element.length > 0) {
        const { DesTipoElemento } = element[0];
        setFieldValue("elementTypeValue", DesTipoElemento);
      }

      setFieldValue("incidentType", "");
      setFieldValue("incidentTypeValue", "");
      setFieldValue("elementType", value);
      this.handleChangeElementType(value);
    };

    const _handleChangeFamilyType = familyType => {
      const { currentTarget } = familyType;
      const { value } = currentTarget;
      const family = families.filter(
        family => family.FamiliasTiposElementosID == value
      );

      if (family.length > 0) {
        const { Nombre } = family[0];
        setFieldValue("familyTypeValue", Nombre);
      }

       setFieldValue("elementType", "");
      setFieldValue("elementTypeValue", "");
      setFieldValue("incidentType", "");
      setFieldValue("incidentTypeValue", "");

      setFieldValue("familyType", value);
      this.handleChangeFamilyType(value);
       };

    const _handleChangeIncidentType = incidentType => {
      const { currentTarget } = incidentType;
      const { value } = currentTarget;

      const incident = incidentsTypes.filter(
        incidentType => incidentType.TipoIncID == value
      );

      if (incident.length > 0) {
        const { TipoInc } = incident[0];
        setFieldValue("incidentTypeValue", TipoInc);
      }

      setFieldValue("incidentType", value);
      this.handleChangeIncidentType(value);

    };

    const _handleChangeUnitType = unitType => {
      const { currentTarget } = unitType;
      const { value } = currentTarget;
      const unit = unitsTypes.filter(
        unitType => unitType.uni_cod == value
      );

      if (unit.length > 0) {
        const { uni_direc } = unit[0];
        setFieldValue("unitTypeValue", uni_direc);
      }

      setFieldValue("unitType", value);
      this.handleChangeUnitType(value);
    };

    const _handleChangePromotionType = promotionType => {
      const { currentTarget } = promotionType;
      const { value } = currentTarget;
      const promotion = promotionsTypes.filter(
        promotionType => promotionType.uni_cod == value
      );

      if (promotion.length > 0) {
        const { uni_direc } = promotion[0];
        setFieldValue("promotionTypeValue", uni_direc);
      }

      setFieldValue("promotionType", value);
      this.handleChangePromotionType(value);
    };


    const _handleLocation = data => {
      const { x, y, value, route, street_number, streetId, buildingId } = data;
      setFieldValue("streetName", value);
      setFieldValue("streetOptions", {
        x,
        y,
        calleID: streetId,
        edificioID: buildingId,
        nomCalle: route,
        numCalle: street_number,
        desUbicacion: value,
        nombreEdificio: ""
      });
    };

    const _handleErrors = errors => {
      alert("El tamaño de la imagen/es es demasiado grande, pruebe a adjuntar una imagen mas pequeña.");
      this.setState({ errors });
    };

    const _handleFiles = async (files, selectedIndex, replace = false) => {
      this.setState({ errors: [] }, async () => {
        const newValue = [...this.state.files];

        if (replace) {
          const image = files[0];
          newValue[selectedIndex] = {
            src: image.src.base64,
            name: image.name,
            size: image.size,
            type: image.type
          };
        } else {
          const convertedImages = [];
          for (let i = 0; i < files.length; i++) {
            const image = files[i];
            convertedImages.push({
              src: image.src.base64,
              name: image.name,
              size: image.size,
              type: image.type
            });
          }

          newValue.splice(selectedIndex, 0, ...convertedImages);
        }
        this.setState({ files: newValue });
        setFieldValue("photos", newValue);
      });
    };

    const _handleDeleteFile = selectedIndex => {
      const newValue = [...this.state.files];
      newValue.splice(selectedIndex, 1);
      this.setState({ files: newValue });
      setFieldValue("photos", newValue);
    };

    const _handleModalClose = () => {
      // setSubmitting(false);
      this.setState({ isSubmitting: false });
      this.setState({ open: false, files: [], errors: [], dragging: false });
      resetForm(initialValuesFormik);
      this.handleModalClose();
    };

    const _handleConfirm = () => {
      this.setState({ isSubmitting: true });
    };

    return (
      <DynamicBackground
        background={
          isWebpSupported()
            ? "https://gecorcloud.com/images/bgNewIncid.webp"
            : "https://gecorcloud.com/images/bgNewIncid.png"
        }
      >
        <div id="new-incident-page">
          <Container>
            <div className="title">
              {t("newincident.title")}{" "}
              {/*entity && (
                <>
                  {" "}
                  -{" "}
                  <div onClick={this.handleChangeEntity}>
                    <span className="tooltiptext">Tooltip text</span>
                    {entity.Nombre}{" "}
                  </div>{" "}
                </>
              )*/}
            </div>
            <Confirm
              title={t("newincident.confirm-title")}
              description={t("newincident.confirm-p1")}
              t={t}
              newIncident={values}
              onConfirm={_handleConfirm}
            >
              {confirm => (
                <Form onSubmit={confirm(handleSubmit)}>
                  <div className="col-sm-12 d-flex flex-column flex-xl-row p-2  m-0">                  
                        <div className="col-lg-6 col-sm-12 p-2">
                         {/* Buscador Tipologias */}
                             <TipologySearch className="tipology-search" onSelected={_handleSelectedTipology} /> 
                          {/* FAMILIAS */}
                          <Form.Group controlId="familyType">
                            <Form.Label>{t("newincident.fam")}</Form.Label>
                            <Form.Control
                              name="familyType"
                              as="select"
                              value={values.familyType}
                              onChange={_handleChangeFamilyType}
                              isInvalid={!!errors.familyType}
                              isValid={touched.familyType && !errors.familyType}
                              disabled={isSubmitting}
                            >
                              <option value={0} disabled={values.familyType}>
                                {t("newincident.p1p")}
                              </option>
                              {families &&
                                families.map(family => (
                                  <option
                                    key={family.FamiliasTiposElementosID}
                                    value={family.FamiliasTiposElementosID}
                                  >
                                    {family.Nombre}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.familyType}
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/*confirm(handleSubmit)*/}
                          <Form.Group controlId="elementType">
                            <Form.Label>{t("newincident.p1")}</Form.Label>
                            <Form.Control
                              name="elementType"
                              as="select"
                              value={values.elementType}
                              onChange={_handleChangeElementType}
                              isInvalid={!!errors.elementType}
                              isValid={touched.elementType && !errors.elementType}
                              disabled={isSubmitting}
                            >
                              <option value={0} disabled={values.elementType}>
                                {t("newincident.p1p")}
                              </option>
                              {elements &&
                                elements.map(element => (
                                  <option
                                    key={element.TipoElementoID}
                                    value={element.TipoElementoID}
                                  >
                                    {element.DesTipoElemento}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.elementType}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="incidentType">
                            <Form.Label>{t("newincident.p2")}</Form.Label>
                            <Form.Control
                              name="incidentType"
                              as="select"
                              value={values.incidentType}
                              onChange={_handleChangeIncidentType}
                              isInvalid={!!errors.incidentType}
                              isValid={touched.incidentType && !errors.incidentType}
                              disabled={isSubmitting}
                            >
                              <option value={0} disabled={values.incidentType}>
                                {t("newincident.p2p")}
                              </option>
                              {incidentsTypes &&
                                incidentsTypes.map(incidentType => (
                                  <option
                                    key={incidentType.TipoIncID}
                                    value={incidentType.TipoIncID}
                                  >
                                    {incidentType.TipoInc}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.incidentType}
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/* Unidad -Emvisesa */}
                          {unitsTypes && unitsTypes.map && unitsTypes.length > 0 && <Form.Group controlId="unitType">
                            <Form.Label>{t("newincident.p6")}</Form.Label>
                            <Form.Control
                              name="unitType"
                              as="select"
                              value={values.unitType}
                              onChange={_handleChangeUnitType}
                              isInvalid={!!errors.unitType}
                              isValid={touched.unitType && !errors.unitType}
                              disabled={isSubmitting}
                            >
                              <option value={0} disabled={values.unitType}>
                                {t("newincident.p6p")}
                              </option>
                              {unitsTypes && unitsTypes.map &&
                                unitsTypes.map(unitType => (
                                  <option
                                    key={unitType.uni_cod}
                                    value={unitType.uni_cod}
                                  >
                                    {unitType.uni_direc}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.unitType}
                            </Form.Control.Feedback>
                          </Form.Group>
                          }
                          {/* Promocion -Emvisesa */}
                          {promotionsTypes && promotionsTypes.map && promotionsTypes.length > 0 && <Form.Group controlId="promotionType">
                            <Form.Label>{t("newincident.p7")}</Form.Label>
                            <Form.Control
                              name="promotionType"
                              as="select"
                              value={values.promotionType}
                              onChange={_handleChangePromotionType}
                              isInvalid={!!errors.promotionType}
                              isValid={touched.promotionType && !errors.promotionType}
                              disabled={isSubmitting}
                            >
                              <option value={0} disabled={values.promotionType}>
                                {t("newincident.p7p")}
                              </option>
                              {promotionsTypes && promotionsTypes.map &&
                                promotionsTypes.map(promotionType => (
                                  <option
                                    key={promotionType.uni_cod}
                                    value={promotionType.uni_cod}
                                  >
                                    {promotionType.uni_direc}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.promotionType}
                            </Form.Control.Feedback>
                          </Form.Group>
                          }

                          <Form.Label>{t("newincident.p4")}</Form.Label>
                          <Form.Group controlId="description">
                            <Form.Control
                              name="description"
                              placeholder={t("newincident.p4p")}
                              as="textarea"
                              rows="5"
                              value={values.description}
                              onChange={handleChange}
                              isInvalid={!!errors.description}
                              isValid={touched.description && !errors.description}
                              disabled={isSubmitting}
                              maxLength={200}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.description}
                            </Form.Control.Feedback>
                          </Form.Group>

                        </div>
                        <div className="col-lg-6 col-sm-12 p-2">
                          {/*  <SimpleMap />   */}
                          <Form.Group controlId="location">
                            <LocationBuildingEngine
                              className="locali"
                              {...this.props}
                              onSearchGecorStreet={this.onHandleSearchStreet}
                              onSuccessLocation={_handleLocation}
                              value={values.streetName}
                            />
                          </Form.Group>

                          <Form.Label>{t("newincident.p3")}</Form.Label>
                          <Form.Group controlId="location">
                            <LocationSearchInput
                              className="locali"
                              {...this.props}
                              onSearchGecorStreet={this.onHandleSearchStreet}
                              onSuccessLocation={_handleLocation}
                              value={values.streetName}
                            />
                          </Form.Group>

                        </div>
                      </div>
                      <div className="col-sm-12 p-2">

                        <Form.Label>{t("newincident.p5")}</Form.Label>
                       <div>{t("newincident.info-imagenes")}   </div> 
                        <Form.Group className="box-img5" controlId="images">
                          <Form.Control
                            as="div"
                            className="photos-container"
                            isInvalid={!!errors.photos}
                            isValid={touched.photos && !errors.photos}
                            disabled={isSubmitting}
                          >
                            <Files
                              multiple={true}
                              convertToBase64={true}
                              maxSize="4mb"
                              multipleMaxSize="4mb"
                              multipleMaxCount={3}
                              accept={["image/jpg", "image/jpeg", "image/png"]}
                              onError={_handleErrors}
                              onSuccess={files =>
                                _handleFiles(files, this.state.files.length)
                              }
                            >
                              {({ browseFiles, getDropZoneProps }) => (
                                <div
                                  {...getDropZoneProps({
                                    className:
                                      gallery +
                                      (this.state.dragging ? " dragging" : ""),
                                    onDragEnter: () =>
                                      this.setState({ dragging: true }),
                                    onDragLeave: () =>
                                      this.setState({ dragging: false }),
                                    onDrop: () => this.setState({ dragging: false })
                                  })}
                                >
                                  <div className="img-display-newinci">
                                    <div className="items-container">
                                      {this.state.files.map((image, index) => (
                                        <div className="img-container" key={index}>
                                          <img
                                            src={image.src}
                                            alt="Foto incidencia"
                                          />
                                          <Button
                                            className="img-trash"
                                            variant="danger"
                                            onClick={() => _handleDeleteFile(index)}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              size="1x"
                                            />
                                          </Button>
                                        </div>
                                      ))}
                                      {this.state.files.length < 4 && (
                                        <li
                                          className="new-image a"
                                          onClick={() => {
                                            if (this.state.files.length < 4)
                                              browseFiles({
                                                onErrors: _handleErrors,
                                                onSuccess: files => {
                                                  // Will append images at the end of the list.
                                                  if (this.state.files.length < 4)
                                                    _handleFiles(
                                                      files,
                                                      this.state.files.length
                                                    );
                                                }
                                              });
                                          }}
                                        >
                                          <div>+</div>
                                        </li>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Files>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.photos}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>  
                  <div className="container-button-env">
                    {!isSubmitting && (
                      <Button
                        className="boton-env"
                        waves="light"
                        type="submit"
                        disabled={!isValid}
                        onClick={() => validateForm()}
                      >
                        {t("newincident.btn")}{" "}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Confirm>
            {isSubmitting && <ProgressBar animated now={100} />}
            {newIncident && (
              <Modal
                show={this.state.open}
                className="resumen-inc"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <h3 className="resumen-inc">{t("newincident.rtitle")}</h3>
                  <p>{t("newincident.rp1")}</p>
                  <p>
                    {t("newincident.rp2")}{" "}
                    <label className="big-bold-font">
                      {" "}
                      {newIncident.entityName}
                    </label>
                  </p>
                  {newIncident.photosForCarousel && (
                    <Carousel>
                      {newIncident.photosForCarousel.map((photo, key) => (
                        <Carousel.Item key={key}>
                          <img
                            className="img-modal responsive-img"
                            alt="Foto incidencia"
                            src={photo}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  )}
                  <p className="rp3-margin">
                    <strong>{t("newincident.rp9")} </strong>
                    <label>{newIncident.familyTypeValue}</label>
                  </p>
                  <p className="rp3-margin">
                    <strong>{t("newincident.rp3")} </strong>
                    <label>{newIncident.elementTypeValue}</label>
                  </p>
                  <p>
                    <strong>{t("newincident.rp4")} </strong>
                    <label>{newIncident.incidentTypeValue}</label>
                  </p>
                  {newIncident.unitType && <p>
                    <strong>{t("newincident.rp7")} </strong>
                    <label>{newIncident.unitTypeValue}</label>
                    -
                    <strong>{t("newincident.rp8")} </strong>
                    <label>{newIncident.promotionTypeValue}</label>
                  </p>}
                  <p>
                    <strong>{t("newincident.rp5")} </strong>
                    <label>{newIncident.streetName}</label>
                  </p>
                  <p>
                    <strong>{t("newincident.rp6")} </strong>
                    <label>{newIncident.description}</label>
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={_handleModalClose}>
                    {t("newincident.rbtn")}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Container>
        </div>
      </DynamicBackground>
    );
  }
}

const initialValuesFormik = {
  familyType: "",
  familyTypeValue: "",
  elementType: "",
  elementTypeValue: "",
  incidentType: "",
  incidentTypeValue: "",
  unitType: "",
  unitTypeValue: "",
  promotionType: "",
  promotionTypeValue: "",
  streetName: "",
  streetOptions: undefined,
  description: "",
  photos: [],
  tipologyQuery: "",
  tipologies: []
};

const tNewIncidentForm = withTranslation()(NewIncidentForm);
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: ({ t, entityConfig }) =>
    Yup.object().shape({
      familyType: Yup.string().required(t("newincident.error-family")), //'Tipo de Familia es obligatorio.'),
      elementType: Yup.string().required(t("newincident.error-element")), //'Tipo de Elemento es obligatorio.'),
      incidentType: Yup.string().required(t("newincident.error-incident")), //'Tipo de Incidencia es obligatoria.'),      
      unitType: Yup.string(), //'Unidad es obligatoria.'),
      promotionType: Yup.string(), //'Promoción es obligatoria.'),
      streetName: Yup.string().required(t("newincident.error-ubication")), //'Localización es obligatoria.'),
      streetOptions: Yup.object().shape({
        x: Yup.number().required(t("newincident.error-ubication")),
        y: Yup.number().required(t("newincident.error-ubication")),
        calleID: Yup.number(),
        edificioID: Yup.number(),
        nomCalle: Yup.string(),
        numCalle: Yup.number(),
        desUbicacion: Yup.string()
      }),
      description: Yup.string().required(t("newincident.error-description")), //'Descripción es obligatoria.'),
      photos: Yup.array()
        .of(
          Yup.object().shape({
            src: Yup.string(),
            name: Yup.string(),
            size: Yup.number(),
            type: Yup.string()
          })
        )
        .min(entityConfig && entityConfig.newIncident ? entityConfig.newIncident.photo.validate ? 1 : 0 : entityConfig && entityConfig.reqNewInc.photo.validate ? 1 : 0, t("newincident.error-photos"))
        .max(4) //'Es obligatorio añadir una foto'
    }),
  mapPropsToValues: () => initialValuesFormik,
  handleSubmit: (payload, { props }) => {
    props.dispatch(incidentsActions.newIncident(payload));
  },
  displayName: "NewIncidentForm"
})(tNewIncidentForm);

const mapStateToProps = state => {
  const { incidents, users } = state;
  const { user } = users;
  const {
    elementType,
    families,
    familyType,
    incidentType,
    newIncident,
    nearbyStreets,
    unitType,
    unitsTypes,
    promotionType,
    promotionsTypes,
    tipologies
  } = incidents;
  const { entities } = state;
  const { entity, entityConfig, streetQuery, externalStreets } = entities;

  if (!user) forwardTo("/login");
  return {
    user,
    entity,
    families,
    familyType,
    elementType,
    incidentType,
    elements: filteredElements(state),
    incidentsTypes: filteredIncidentsType(state),
    unitsTypes,
    unitType,
    promotionType,
    promotionsTypes,
    newIncident,
    nearbyStreets,
    entityConfig,
    streets: filteredStreets(state),
    externalStreets,
    buildings: filteredBuildings(state),
    streetQuery,
    tipologies
  };
};

const tFormikEnhancer = withTranslation()(formikEnhancer);
const connectedNewIncidentPage = connect(mapStateToProps, null, null, { areStatesEqual: () => false } )(tFormikEnhancer);
export { connectedNewIncidentPage as NewIncidentPage };
