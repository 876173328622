import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./LoginPage.css";
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { forwardTo } from '../../helpers'
import {
  loginRequest,
  clearError,
  userLogout,
  entityActions,
  socialLogin,
  socialLogout,
  resetPasswordRequest
} from "../../actions";
import { defaultAyuntamientoID, googleClientId, facebookAppId } from '../../constants';
// import { forwardTo } from './../../helpers';
import queryString from "query-string";
import {
  DynamicBackground,
  EntitySearch,
  SocialLogin,
  BannerCertificate
} from "./../../components";

import { history } from "./../../helpers";
import { withTranslation } from "react-i18next";
import { isWebpSupported } from "react-image-webp/dist/utils";

class LoginPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      open: false,
      ao: queryString.parse(this.props.location.search),
      loginRequired: this.props.location.state ? this.props.location.state.loginRequired : false,
      hideChangeEntity: defaultAyuntamientoID == 1 ? true : false,
      entityName: 'Gecor',
      errorReset: "",
    };
    this.props.prepareUser();
    this.props.clearError();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseLoginRequiredModal = this.handleCloseLoginRequiredModal.bind(this);

    const { ao } = this.state;
    if (ao && ao.ao) {
      this.props.getEntityById({ id: ao.ao, redirect: false });
    } else if (defaultAyuntamientoID == 1) {
      this.props.getEntityById({ id: defaultAyuntamientoID, redirect: false });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.prepareGoogle();
    this.props.prepareFacebook();
    if (!this.state.ao) this.props.loadLocalEntity();
  }

  handleChange(e) {
    const { error } = this.props;
    if (error) this.props.clearError();

    const { name, value } = e.target;
    this.setState({ [name]: value, errorReset: "" });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { entity } = this.props;
    if (email && password && entity) {
      this.props.login({ email, password, entity });
      //dispatch(userLogin.login(username, password, entity));
    }
  }

   handleResetPassword(e) {
    e.preventDefault();
    const { emailModal: email } = this.state;
    const { resetPassword } = this.props;
    
    if (email) {
        this.props.resetPassword({ email });
        if (resetPassword) {
          // this.setState({ open: false, emailModal: "", errorReset: "" });
          this.setState({ successReset: "login.resetSucess" })
        } else {
          this.setState({ errorReset: "login.emailNotValid" });
        }
    }
  }

  handleClose() {
    this.setState({ open: false, errorReset: "", successReset: ""  });
  }

  handleCloseLoginRequiredModal() {
    this.setState({ loginRequired: false });
    history.replace({ pathname: '/login', state: {} })
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  handleChangeEntity(e) {
    e.preventDefault();

    const { ao, hideChangeEntity } = this.state;
    if ((ao && !ao.ao) || !ao) {
      if (defaultAyuntamientoID != 1 && !hideChangeEntity)
        this.props.changeEnttity();
    }
  }

  handleChangeBackground(entity) {
    switch (entity && entity.AyuntamientoID) {
      case 206:
        return "";
      case 180:
        return entity.CabeceraApp;
      case 429:
        this.setState({ hideChangeEntity: true, entityName: "Viladecans Soluciona" });
        return isWebpSupported()
          ? "https://gecorcloud.com/images/turisme1.webp"
          : "https://gecorcloud.com/images/turisme1.jpg";
      case 294:
        this.setState({ hideChangeEntity: true });
        return isWebpSupported()
          ? "https://gecorcloud.com/images/login.webp"
          : "https://gecorcloud.com/images/login.png";
      default:
        return isWebpSupported()
          ? "https://gecorcloud.com/images/login.webp"
          : "https://gecorcloud.com/images/login.png";
    }
  }

  handleChangePolitic(entity) {
    switch (entity && entity.AyuntamientoID) {
      case 180:
        return "data-tcotillas";
      default:
        return "hide-tcotillas";
    }
  }

  handleSelectedEntity(entity) {
    //this.setState({ aytoName: entity.Nombre });
  }

  render() {
    const { entity, currentlySending, error, t } = this.props;
    const { email, password, submitted, ao, loginRequired, hideChangeEntity, entityName, errorReset, successReset  } = this.state;

    return (
      <DynamicBackground
        customOverlay="rgba(0, 0, 0, 0.5)"
        background={this.handleChangeBackground(entity)}
      >
        <div className="containerl">
          <div id="login-page">
            <div className="container">
              {loginRequired &&
                <Modal show={loginRequired} onHide={this.handleCloseLoginRequiredModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t('login.modalLoginRequired.tittle')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {t('login.modalLoginRequired.body')}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseLoginRequiredModal}>
                      {t('login.modalLoginRequired.btnAccept')}
                    </Button>
                  </Modal.Footer>
                </Modal>}
              {((ao && !ao.ao) || !ao) && !entity && (
                <EntitySearch
                  redirect={false}
                  onSelected={this.handleSelectedEntity}
                  ao={ao}
                />
              )}
              {entity && (
                <div className="omb_login d-flex justify-content-center">
                  <div className="loginBox">
                    <Card>
                      <Row className="center">
                        <Col sm={12}>
                          <div
                            className="changent"
                            onClick={this.handleChangeEntity}
                          >
                            {entity.AyuntamientoID!=0 && <img className="img-title-logo"
                            src={entity.Logo}
                            alt={entity.AyuntamientoID!=0?"logo":""}
                            />}
                            <span className="span-title-logo">
                              {entity.Nombre}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <hr className="line7"></hr>
                      <Card.Body>
                        <blockquote className="blockquote mb-0">
                          <Row className="omb_row-sm-offset-3">
                            <Col md={12} lg={6}>
                              <h5 className="title-register">
                                {t("login.title-login")}
                              </h5>
                              <form
                                name="loginForm"
                                onSubmit={this.handleSubmit}
                                className="loginForm"
                              >
                               {/* {error && (
                                  <span className="red-text helper-text">
                                    {" "}
                                    {error}{" "}
                                  </span>
                                )}
                               */}
                                <Row>
                                  <InputGroup className="mb-3">
                                    <FormControl
                                      type="email"
                                      placeholder={t("login.mail")}
                                      aria-label="Dirección email"
                                      aria-describedby="basic-addon2"
                                      error={
                                        submitted && !email
                                          ? "Email is required"
                                          : ""
                                      }
                                      value={email}
                                      name="email"
                                      onChange={this.handleChange}
                                    />
                                  </InputGroup>

                                  </Row>
                                <Row>
                                  <InputGroup className="mb-3">
                                    <FormControl
                                      type="password"
                                      placeholder={t("login.pass")}
                                      aria-label="Contraseña"
                                      aria-describedby="basic-addon2"
                                      error={
                                        submitted && !password
                                          ? "Password is required"
                                          : ""
                                      }
                                      value={password}
                                      name="password"
                                      onChange={this.handleChange}
                                    />
                                  </InputGroup>
                                </Row>
                                {error && (
                                  <span className="red-text helper-text">
                                    {" "}
                                    {t(error)}{" "}
                                  </span>
                                )}
                                <Row>
                                  <Button
                                    variant="info"
                                    type="submit"
                                    size="lg"
                                  >
                                    {t("login.btn")}
                                  </Button>
                                </Row>
                              </form>
                              <div className="recuperate">
                                <p className="omb_forgotPwd">
                                  <a
                                    rel="noopener noreferrer"
                                    href="#"
                                    onClick={this.handleShow}
                                  >
                                    {t("login.f-pass")}
                                  </a>
                                </p>
                                {((ao && !ao.ao) || !ao) && entity && !hideChangeEntity && (
                                  <p className="omb_forgotPwd">
                                    <a
                                      rel="noopener noreferrer"
                                      href="#"
                                      onClick={this.handleChangeEntity}
                                    >
                                      {" "}
                                      {t("login.ch-ayto")}
                                    </a>
                                  </p>
                                )}
                                {/*((ao && !ao.ao) || !ao) && entity &&
                                      <Row className="center">
                                           <Col sm={12}>
                                            <span> {entity.Nombre} - <a href='#' onClick={this.handleChangeEntity}>{t('login.ch-ayto')}</a></span>
                                      </Col>
                                  </Row>
                              */}
                              </div>
                            </Col>
                            <Col lg={1}>
                              <hr className="hr-1"></hr>
                            </Col>
                            <Col md={12} lg={5}>
                              <div className="otherLog">
                                <h5 className="title-login">
                                  {t("login.reg-p1")}
                                </h5>
                                <p className="center-align w-100">
                                  {t("login.reg-p2", { entityName: entity.AliasAPP ? entity.AliasAPP : "Gecor" })}
                                </p>
                                <Row className="social-media-register">
                                  <Col sm={12}>
                                    <SocialLogin {...this.props} />
                                  </Col>
                                </Row>
                                <Row className={this.handleChangePolitic(entity)} >
                                  <Col sm={12}>
                                    <p>{t("login.ppriv-p1")}<a className="user-politic-tcotilla" onClick={() => forwardTo('/UserDataTcotillasPage')} target="_blank"> {t("login.ppriv-p2")}</a></p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </blockquote>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )}

              {currentlySending && (
                <div className="loader-container">
                  <div className="preloader-wrapper big active loader">
                    <div className="spinner-layer spinner-blue-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Modal
            show={this.state.open}
            aria-labelledby="example-modal-sizes-title-sm"
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("login.cont-olv")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>
                {t("login.input-mail")}
              </label>
              <Row>
                <Col s={12} m={{ span: 6, offset: 3 }}>
                  <Form.Control
                    className="center-align"
                    type="email"
                    name="emailModal"
                    onChange={this.handleChange}
                    placeholder={t("login.mail")}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
            {errorReset && (<span className="red-text helper-text">
                {" "}
                {t(errorReset)}{" "}
              </span>
              )}
              {successReset && (<span className="sucess-text helper-text">
                {" "}
                {t(successReset)}{" "}
              </span>
              )}
              <Button onClick={this.handleResetPassword}>{t("login.acept")}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </DynamicBackground>
    );
  }
}

function mapStateToProps(state) {
  const { entities, users } = state;
  const { error, currentlySending, user } = users;
  const { entity } = entities;
  return {
    entity,
    user,
    error,
    currentlySending
  };
}

const mapDispatchToProps = dispatch => ({
  prepareGoogle: () =>
    dispatch(socialLogin.prepare("google", { client_id: googleClientId })),
  prepareFacebook: () =>
    dispatch(socialLogin.prepare("facebook", { appId: facebookAppId })),
  loginFacebook: () => dispatch(socialLogin.request("facebook")),
  loginGoogle: (token) => dispatch(socialLogin.request("google", token)),
  logout: () => dispatch(socialLogout()),
  prepareUser: () => dispatch(userLogout()),
  loadLocalEntity: () => dispatch(entityActions.getLocalEntity()),
  changeEnttity: () => dispatch(entityActions.changeEntity()),
  getEntityById: data => dispatch(entityActions.getEntityById(data)),
  login: data => dispatch(loginRequest(data)),
  resetPassword: data => dispatch(resetPasswordRequest(data)),
  clearError: () => dispatch(clearError())
});
const tLoginPage = withTranslation()(LoginPage);
const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(tLoginPage);


export { connectedLoginPage as LoginPage };
