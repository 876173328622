import './App.css';
import React, { Component, useState, useEffect } from 'react';
import { Router, Route, Redirect, Switch, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Toast from 'react-bootstrap/Toast';
import { withTranslation } from 'react-i18next';
import { history, forwardTo } from '../helpers';

import { HomePage } from '../pages/HomePage/HomePage';
import { RulesPage } from '../pages/RulesPage';

import { CitiesPage } from '../pages/CitiesPage/CitiesPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { UsaPage } from '../pages/UsaPage';
import { messaging } from '../../init-fcm';
import { EntitySearch, BannerCertificate } from '../components/'

// import DevTools from './DevTools';

/*********** AYUNTAMIENTO Y USUARIOS ***************/
import { defaultAyuntamientoID } from '../constants';
import { EntitiesPage } from '../pages/EntitiesPage';

import { LoginPage } from '../pages/LoginPage';
import { UserSettingPage } from '../pages/UserSettingPage';
import { RegisterPage } from '../pages/RegisterPage';
import { ForgotPassPage } from '../pages/ForgotPassPage';
import { ContestPage } from '../pages/ContestPage';
import { FaqPage } from '../pages/FaqPage';
import { CitizenGuidePage } from '../pages/CitizenGuidePage/CitizenGuidePage';

/*********** FOOTER: AVISO LEGAL ACCESIBILIDAD FAQ CONTACTO ***************/
import { DeclaracionAccPage } from '../pages/DeclaracionAccesibilidad'
import { PolicyPDF } from '../pages/PrivatePoliticsPage'
import { PrivacyPolicyAppPage } from '../pages/PrivacyPolicyAppPage'




import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";



import { CookiePage } from '../pages/CookiePage';
import { LegalAdvicePage } from '../pages/LegalAdvicePage';
import { PrivatePoliticsPage } from '../pages/PrivatePoliticsPage';
import { ContactPage } from '../pages/ContactPage';
import { ContactCoPage } from '../pages/ContactCoPage';
import { ContactAytoPage } from '../pages/ContactAytoPage';

/*********** INCIDENCIAS ***************/
import { IncidentsPage } from '../pages/IncidentsPage';
import { NearbyIncidentsPage } from '../pages/NearbyIncidentsPage';
import { IncidentDetailPage } from '../pages/IncidentDetailPage';
import { NewIncidentPage } from '../pages/NewIncidentPage';

/*********** PUNTOS DE INTERES ***************/
import { PoisPage } from '../pages/PoisPage';
import { PoiDetailPage } from '../pages/PoiDetailPage';
import { PoisMapPage } from '../pages/PoisMapPage';

/*********** ENCUESTAS ***************/
import { SurveysPage } from '../pages/SurveysPage';
import { SurveyDetailPage } from '../pages/SurveyDetailPage';

/*********** INFORMACION GECOR ***************/
import { FaqGecorPage } from '../pages/FaqGecorPage';
import { GweetPage } from '../pages/GweetPage';
import { GecorBlogHomePage } from '../pages/GecorBlogHomePage';
import { CompanyPage } from '../pages/CompanyPage';
import { GecorFormsPage } from '../pages/GecorFormsPage';
import { GweetFormsPage } from '../pages/GweetFormsPage';
import { ClickDigitalPage } from '../pages/ClickDigitalPage';


/*******Noticias************/
import { DifusionDetail } from '../components/DifusionDetail/DifusionDetail'
import { NoticePage } from '../pages/NoticePage';
import { NoticeExtendPage } from '../pages/NoticeExtendPage';

/*********** PERSONALIZADAS ***************/
import { UserDataTcotillasPage } from '../pages/UserDataTcotillasPage';


/*********** ROJALES MEDIO AMBIENTE ***************/
import { MenuRojalesPage } from '../pages/Enviroment/Rojales/MenuRojalesPage';
import { ResiduosRojalesPage } from '../pages/Enviroment/Rojales/ResiduosRojalesPage';

/*********** VALVERDE MEDIO AMBIENTE ***************/
import { MenuValverdePage } from '../pages/Enviroment/Valverde/MenuValverdePage';
import { NatureValverdePage } from '../pages/Enviroment/Valverde/NatureValverdePage';

/*********** TORREMOLINOS MEDIO AMBIENTE ***************/
import { MenuTorremolinosPage } from '../pages/Enviroment/Torremolinos/MenuTorremolinosPage';
import { LocalInformationPage } from '../pages/Enviroment/Torremolinos/LocalInformationPage/LocalInformationPage';
import { EnviromentalEducationPage } from '../pages/Enviroment/Torremolinos/EnviromentalEducationPage';
import { EnviromentalInformationPage } from '../pages/Enviroment/Torremolinos/EnviromentalInformationPage';

/***********CHICLANA MEDIO AMBIENTE ***************/
import { ContactAytoPageCh } from '../pages/Enviroment/Chiclana/ContactAytoPageCh';

import { MenuPage } from '../pages/Enviroment/Chiclana/MenuPage';
import { EnviromentalInformationPageCh } from '../pages/Enviroment/Chiclana/EnviromentalInformationPageCh';
import { EnviromentalEducationPageCh } from '../pages/Enviroment/Chiclana/EnviromentalEducationPageCh';
import { EnviromentalContactPageCh } from '../pages/Enviroment/Chiclana/EnviromentalContactPageCh';
import { LocalInformationPageCh } from '../pages/Enviroment/Chiclana/LocalInformationPageCh';
import { CitizenGuidePageCh } from '../pages/Enviroment/Chiclana/CitizenGuidePageCh';

import { FaqPageCh } from '../pages/FaqPageCh';
/***********LA LÍNEA MEDIO AMBIENTE ***************/
import { IncidentsPageLn } from '../pages/IncidentsPageLn';

import { MenuLineaPage } from '../pages/Enviroment/Linea/MenuLineaPage';
import { EnviromentalInformationPageLn } from '../pages/Enviroment/Linea/EnviromentalInformationPageLn';
import { EnviromentalContactPageLn } from '../pages/Enviroment/Linea/EnviromentalContactPageLn';
import { CitizenGuidePageLn } from '../pages/Enviroment/Linea/CitizenGuidePageLn';
import { LocalInformationPageLn } from '../pages/Enviroment/Linea/LocalInformationPageLn';
import { EnviromentalEducationPageLn } from '../pages/Enviroment/Linea/EnviromentalEducationPageLn';

/***********PUERTO DEL ROSARIO MEDIO AMBIENTE ***************/

import { MenuPuertoRosarioPage } from '../pages/Enviroment/PuertoRosario/MenuPuertoRosarioPage';
import { IncidentsPagePr } from '../pages/IncidentsPagePr';

import { EnviromentalInformationPagePr } from '../pages/Enviroment/PuertoRosario/EnviromentalInformationPagePr';
import { ContactAytoPagePr } from '../pages/Enviroment/PuertoRosario/ContactAytoPagePr';
import { EnviromentalEducationPagePr } from '../pages/Enviroment/PuertoRosario/EnviromentalEducationPagePr';
import { LocalInformationPagePr } from '../pages/Enviroment/PuertoRosario/LocalInformationPagePr';
import { EnviromentalContactPagePr } from '../pages/Enviroment/PuertoRosario/EnviromentalContactPagePr';
import { CitizenGuidePagePr } from '../pages/Enviroment/PuertoRosario/CitizenGuidePagePr';

/*********** ONIL MEDIO AMBIENTE ***************/
import { MenuOnilPage } from '../pages/Enviroment/Onil/MenuOnilPage';
import { EnviromentalInformationPageOn } from '../pages/Enviroment/Onil/EnvInformationPageOn';
import { EnviromentalContactPageOn } from '../pages/Enviroment/Onil/EnvContactPageOn';
import { CitizenGuidePageOn } from '../pages/Enviroment/Onil/CitizenGuidePageOn';
import { LocalInformationPageOn } from '../pages/Enviroment/Onil/LocalInformationPageOn';
import { EnviromentalEducationPageOn } from '../pages/Enviroment/Onil/EnvEducationalPageOn';

/*********** TORRELAVEGA MEDIO AMBIENTE ***************/
import { MenuTorrelavegaPage } from '../pages/Enviroment/Torrelavega/MenuTorrelavegaPage';
import { EnviromentalInformationPageTv } from '../pages/Enviroment/Torrelavega/EnvInformationPageTv';
import { EnviromentalContactPageTv } from '../pages/Enviroment/Torrelavega/EnvContactPageTv';
import { CitizenGuidePageTv } from '../pages/Enviroment/Torrelavega/CitizenGuidePageTv';
import { LocalInformationPageTv } from '../pages/Enviroment/Torrelavega/LocalInformationPageTv';
import { EnviromentalEducationPageTv } from '../pages/Enviroment/Torrelavega/EnvEducationalPageTv';

/***********ALCORCON***************/
/*
import { SostenibilidadComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/SostenibilidadComponent';
import { ResiduosComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/ResiduosComponent'
import { CalidadAireComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/CalidadAireComponent'
import { BiodiversidadComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/BiodiversidadComponent'
import { VertidosIndustriales } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/VertidosIndustriales'
import { ProteccionContaminacionAcustica } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/ProteccionContaminacionAcustica'
import { AhorroEnergiaComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Sostenibilidad/AhorroEnergiaComponent'
import { MovilidadComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/MovilidadComponent'
import { AlcorconPieComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/AlcorconPieComponent'
import { AlcorconBiciComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/AlcorconBiciComponent'
import { AlcorconTransPublicoComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/AlcorconTransPublicoComponent'
import { AlcorconAparCocheComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/AlcorconAparCocheComponent'
import { MMovilidadComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Movilidad/mMovilidadComponent'
import { RenovablesComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Renovables/RenovablesComponent'
import { EnergiaRenovablesComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Renovables/EnergiaRenovablesComponent'
import { ComEnergeticaLocalComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Renovables/ComEnergeticaLocalComponent'
import { AyudasInstalacionComponent } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc/Renovables/AyudasInstalacionComponent'


import { MenuAlcorconPage } from '../pages/Enviroment/Alcorcon/MenuAlcorconPage';
import { EnviromentalContactPageAc } from '../pages/Enviroment/Alcorcon/EnviromentalContactPageAc';
import { LocalInformationPageAc } from '../pages/Enviroment/Alcorcon/LocalInformationPageAc';
import { ContenidoRelacionadoAc } from '../pages/Enviroment/Alcorcon/ContenidoRelacionadoAc';
*/
// import { EnviromentalEducationPageAc } from '../pages/EnviromentalEducationPageAc';

/***********END ALCORCON***********/

import { SecurityPolicyPage } from '../pages/SecurityPolicyPage/SecurityPolicyPage';


import {
  PrivateRoute,
  PrivateRouteWithCheckFields,
  FooterComponent,
  FooterComponent_143,
  FooterComponent_143e,
  FooterComponent_411,
  FooterComponent_411e,
  FooterComponent_10411,
  FooterComponent_294,
  FooterComponent_294e,
  FooterComponent_430e,
  FooterComponent_268,
  FooterComponent_268e,
  FooterComponent_206,
  FooterComponent_405,
  FooterComponent_429,
  FooterComponent_10410,
  FooterComponent_10423,
  FooterComponent_1,
  FooterComponent_ue,
  Footer_ue,
  Footer_180,
  FooterComponent_10400,
  FooterUsaComponent,
  Header,
  Header_411,
  Header_411e,
  Header_294,
  Header_294e,
  Header_430e,
  Header_429,
  Header_10410,
  Header_10411,
  Header_10423,
  Header_10438e,
  Header_143,
  Header_143e,
  Header_180,
  Header_206,
  Header_268,
  Header_268e,
  Header_405,
  Header_1,
  Header_311e
} from "../components";

/* ICONS */
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons";
import { DevelopersPage } from '../pages/Developers/DevelopersPage';
import FooterComponent_10438 from '../components/Footer/Footer_10438';
library.add(faStroopwafel);

const Push = () => {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({});

  messaging.onMessage(payload => {
    const { data } = payload;
    setNotification(data)
  });
  //
  const { Titulo, Subtitulo, Enlace, UrlImagen } = notification

  return (
    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
      <Toast.Header>
        <img
          src={UrlImagen ? UrlImagen : "holder.js/20x20?text=%20"}
          className="rounded mr-2"
          alt="round"
        />
        <strong className="mr-auto">{Titulo}</strong>
        <small>11 mins ago</small>
      </Toast.Header>
      <Toast.Body>{Subtitulo}</Toast.Body>
    </Toast>
  );
}

const Galletas = () => {
  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({

      // root: 'body',
      // autoShow: true,
      // disablePageInteraction: true,
      // hideFromBots: true,
      // mode: 'opt-in',
      // revision: 0,

      cookie: {
          name: 'cc_cookie',
          // domain: location.hostname,
          // path: '/',
          // sameSite: "Lax",
          // expiresAfterDays: 365,
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
      guiOptions: {
          consentModal: {
              layout: 'cloud inline',
              position: 'bottom center',
              equalWeightButtons: true,
              flipButtons: false
          },
          preferencesModal: {
              layout: 'box',
              equalWeightButtons: true,
              flipButtons: false
          }
      },

      onFirstConsent: ({cookie}) => {
          console.log('onFirstConsent fired',cookie);
      },

      onConsent: ({cookie}) => {
          console.log('onConsent fired!', cookie)
      },

      onChange: ({changedCategories, changedServices}) => {
          console.log('onChange fired!', changedCategories, changedServices);
      },

      onModalReady: ({modalName}) => {
          console.log('ready:', modalName);
      },

      onModalShow: ({modalName}) => {
          console.log('visible:', modalName);
      },

      onModalHide: ({modalName}) => {
          console.log('hidden:', modalName);
      },

      categories: {
          necessary: {
              enabled: true,  // this category is enabled by default
              readOnly: true  // this category cannot be disabled
          },
          analytics: {
              autoClear: {
                  cookies: [
                      {
                          name: /^_ga/,   // regex: match all cookies starting with '_ga'
                      },
                      {
                          name: '_gid',   // string: exact cookie name
                      }
                  ]
              },

              // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
              services: {
                  ga: {
                      label: 'Google Analytics',
                      onAccept: () => {},
                      onReject: () => {}
                  },
                  youtube: {
                      label: 'Youtube Embed',
                      onAccept: () => {},
                      onReject: () => {}
                  },
              }
          },
          ads: {}
      },

      language: {
          default: 'en',
          autoDetect: 'browser',
          translations: {
            en: {
              consentModal: {
                  title: 'We use cookies',
                  description: 'We use our own and third-party cookies to analyze our services and show you advertising related to your preferences based on a profile created from your browsing habits (such as the pages visited).',
                  acceptAllBtn: 'Accept all',
                  acceptNecessaryBtn: 'Reject all',
                  showPreferencesBtn: 'Manage Individual preferences',
                  // closeIconLabel: 'Reject all and close modal',
                  footer: `
                      <a href="#path-to-impressum.html" target="_blank">Impressum</a>
                      <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
                  `,
              },
              preferencesModal: {
                  title: 'Manage cookie preferences',
                  acceptAllBtn: 'Accept all',
                  acceptNecessaryBtn: 'Reject all',
                  savePreferencesBtn: 'Accept current selection',
                  closeIconLabel: 'Close modal',
                  serviceCounterLabel: 'Service|Services',
                  sections: [
                      {
                          title: 'Your Privacy Choices',
                          description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
                      },
                      {
                          title: 'Strictly Necessary',
                          description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                          //this field will generate a toggle linked to the 'necessary' category
                          linkedCategory: 'necessary'
                      },
                      {
                          title: 'Performance and Analytics',
                          description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                          linkedCategory: 'analytics',
                          cookieTable: {
                              caption: 'Cookie table',
                              headers: {
                                  name: 'Cookie',
                                  domain: 'Domain',
                                  desc: 'Description'
                              },
                              body: [
                                  {
                                      name: '_ga',
                                      domain: history.location.hostname,
                                      desc: 'The main cookie used by Google Analytics is "_ga", which allows the services to distinguish one visitor from another and has a duration of 2 years.',
                                  },
                                  {
                                      name: '_gid',
                                      domain: history.location.hostname,
                                      desc: 'Cookie used to differentiate users during a single day',
                                  }
                              ]
                          }
                      },
                      {
                          title: 'Targeting and Advertising',
                          description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                          linkedCategory: 'ads',
                      },
                      {
                          title: 'More information',
                          description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                      }
                  ]
              }
          
            },
              es: {
                consentModal: {
                    title: 'Usamos cookies',
                    description: 'Utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarte publicidad relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación (como por ejemplo, las páginas visitadas)',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    showPreferencesBtn: 'Controlar Preferencias',
                    // closeIconLabel: 'Reject all and close modal',
                    footer: `
                        <a href="https://gecorcloud.com/pdf/politica-de-cookies.pdf" target="_blank">Cookies</a>
                        <a href="https://gecorcloud.com/pdf/politica-de-privacidad.pdf" target="_blank">Politica de Privacidad</a>
                    `,
                },
                preferencesModal: {
                    title: 'Controlar Preferencias de cookies',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    savePreferencesBtn: 'Aceptar',
                    closeIconLabel: 'Cerrar modal',
                    serviceCounterLabel: 'Servicio|Servicios',
                    sections: [
                        {
                            title: 'Tus opciones de privacidad',
                            description: `En este panel, puedes expresar algunas preferencias relacionadas con el tratamiento de tu información personal. Puedes revisar y cambiar las opciones expresadas en cualquier momento reabriendo este panel a través del enlace proporcionado. Para denegar tu consentimiento a las actividades específicas de procesamiento descritas a continuación, cambia los interruptores a "apagado" o utiliza el botón "Rechazar todo" y confirma que deseas guardar tus elecciones.`,
                        },
                        {
                            title: 'Estrictamente necesarias',
                            description: 'Estas cookies son esenciales para el correcto funcionamiento del sitio web y no se pueden desactivar.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Rendimiento y análisis',
                            description: 'Estas cookies recopilan información sobre cómo utilizas nuestro sitio web. Todos los datos son anónimos y no pueden usarse para identificarte.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Tabla de cookies',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                  {
                                      name: '_ga',
                                      domain: history.location.hostname,
                                      desc: 'La principal cookie que utiliza Google Analytics es "_ga", que permite a los servicios distinguir a un visitante de otro y tiene una duración de 2 años.',
                                  },
                                  {
                                      name: '_gid',
                                      domain: history.location.hostname,
                                      desc: 'Cookie utilizada para diferenciar a los usuarios durante un único día',
                                  }
                              ]
                            }
                        },
                        {
                            title: 'Segmentación y publicidad',
                            description: 'Estas cookies se utilizan para hacer que los mensajes publicitarios sean más relevantes para ti y tus intereses. La intención es mostrar anuncios que sean pertinentes y atractivos para el usuario individual, y así sean más valiosos para los editores y anunciantes de terceros.',
                            linkedCategory: 'ads',
                        },
                        {
                            title: 'Más informacioón',
                            description: 'Para cualquier consulta relacionada con mi política sobre cookies y tus opciones, por favor <a href="https://gecorweb.com/contact">contactanos</a>'
                        }
                    ]
                }
            }
          }
      }
    });
  }, []);

  return (null);
}

const DeepLinkHandler = () => {
  const { url } = useParams();

  useEffect(() => {
    // Decode the deep link from the QR code
    const deepLink = decodeURIComponent(url);

    // Attempt to open the deep link
    window.location.href = deepLink;

    // Optionally, handle fallback after a delay if the app is not opened
    const fallbackTimeout = setTimeout(() => {
      alert(
        "If the app didn't open, please download it from the App Store or Google Play."
      );
      // Redirect to fallback URL (e.g., app store or website)
      // window.location.href = 'https://example.com/app-download';
    }, 3000); // Wait 3 seconds before fallback

    // Cleanup timeout on component unmount
    return () => clearTimeout(fallbackTimeout);
  }, [url]);

  return (
    <div>
      <p>Attempting to open the app...</p>
      <p>
        If nothing happens, you may need to{' '}
        <a href="https://example.com/app-download">download the app</a>.
      </p>
    </div>
  );
};


class App extends Component {
  constructor(props) {
    super(props);

    let Ayto = history.location.pathname.split('/').map(function (ayto) {
      if (ayto == 'alcorcon') {
        return { ao: 430 }
      }
      if (ayto == 'valverde') {
        return { ao: 10411 }
      }
      if (ayto == 'tcotillas') {
        return { ao: 180 }
      }
    })[1];

    const hostSplited = window.location.host.split(".");
    if (hostSplited[0] == 'soluciona') {
      Ayto = { ao: 429 }
    } else if (hostSplited[0] == 'lalineaentumano' || hostSplited[1] == 'lalineaentumano') {
      Ayto = { ao: 294 }
    }

    this.state = {
      urlParams: history.location ? queryString.parse(history.location.search) : {},
      isUsa: history.location && history.location.pathname == "/usa",
      isNotFound:
        history.location && history.location.pathname == "/404"
          ? true
          : history.location.search
            ? true
            : false,
      ao: Ayto
    };
    localStorage.setItem("Server", this.state.urlParams.location)
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);

    if(['/login', '/contact', '/faq-g', '/politica-privacidad-app', '/security-policy', '/Declaracion-Accesibilidad', '/deeplink']
      .some(pathName => history.location.pathname.includes(pathName))
    ) {// if(history.location.pathname == "/login") {
      // evitar redirect si esta en la pantalla del login
    } else if(this.state
      && (!this.state.ao || (this.state.ao && !this.state.ao.ao))
      && (!this.state.urlParams || (this.state.urlParams && !this.state.urlParams.ao))) {
      window.location = "https://gecorsystem.com";
    }

  }
  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }

  componentDidMount() {
    this.unlisten = history.listen(location => {
      if (location.pathname == "/usa") this.setState({ isUsa: true });
      else this.setState({ isUsa: false });
    });

    if (messaging)
      messaging
        .requestPermission()
        .then(() => {
          console.log("FCM token", "Notifications allowed")
          return messaging.getToken();
        })
        .then(token => {
          localStorage.setItem("token", token);
          console.log("FCM token", token)
        })
        .catch(err => {
          console.error("FCM token", "Unable to get permission to notify", err);
        });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { entity, t } = this.props;
    const { isUsa, isNotFound, urlParams, ao } = this.state;

    return (
      <Router history={history}>
        <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
        <React.Fragment>
          {/* Header */}
          {(() => {
            if (!isUsa)
              switch (entity && entity.AyuntamientoID) {
                case 143:
                  if (urlParams && urlParams.header) {
                    return <Header_143e {...this.props} />; //Medio Ambiente
                  } else {
                    return <Header_143 {...this.props} />; //Torremolinos
                  }
                case 206:
                  return <Header_206 {...this.props} />; //Granada
                case 180:
                  return <Header_180 {...this.props} />; //Torres de Cotillas  
                case 411:
                  if (urlParams && urlParams.header) {
                    return <Header_411e {...this.props} />; //Medio Ambiente
                  } else {
                    return <Header_411 {...this.props} />; //Puerto del Rosario
                  }
                case 294:
                  if (urlParams && urlParams.header) {
                    return <Header_294e {...this.props} />; //Medio Ambiente
                  } else {
                    return <Header_294 {...this.props} />; //La Línea
                  }
                case 430:
                  return <Header_430e {...this.props} />; //Medio Ambiente
                case 405:
                  return <Header_405 {...this.props} />; //Arroyo de la encomienda
                case 1:
                  return <Header_1 {...this.props} />; //Arroyo de la encomienda
                case 429:
                  return <Header_429 {...this.props} />; //Viladecans
                case 10410:
                  return <Header_10410 {...this.props} />; //Adeje
                case 268:
                  if (urlParams && urlParams.header) {
                    return <Header_268e {...this.props} />; //Medio Ambiente
                  } else {
                    return <Header_268 {...this.props} />; //Chiclana
                  }
                case 10411:
                  if (urlParams && urlParams.header) {
                    return <Header_10411 {...this.props} />; //Medio Ambiente Valverde
                  } else {
                    return <Header_10411 {...this.props} />; //Valverde
                  }
                case 10423:
                  if (urlParams && urlParams.header) {
                    return <Header_10423 {...this.props} />; //Medio Ambiente Rojales
                  } else {
                    return <Header_10423 {...this.props} />; //Rojales
                  }
                case 10438:
                    return <Header_10438e {...this.props} />; //Medio Ambiente Onil
                case 311:
                  if (urlParams && urlParams.header) {
                    return <Header_311e {...this.props} />; //Medio Ambiente Torrelavega
                  } else {
                    return <Header {...this.props} />; //Torrelavega
                  }
                default:
                  return <Header {...this.props} hbtn={urlParams.hbtn} />; //Genérico
              }
          })()}
          {/* End Header */}
          {/* DevTools  */}
          {/*<DevTools />*/}
          {/* End DevTools */}
          <Switch>
            <Route exact path="/" component={(defaultAyuntamientoID == 1) || (entity && entity.AyuntamientoID) == 206 || (entity && entity.AyuntamientoID) == 405
              || (entity && entity.AyuntamientoID) == 429 || (entity && entity.AyuntamientoID) == 294 ? IncidentsPage : HomePage} />
            <Route path="/usa" component={UsaPage} />
            <PrivateRouteWithCheckFields path="/new-incident" component={NewIncidentPage} />
            <PrivateRoute path="/incidents" component={IncidentsPage} />
            <PrivateRoute path="/nearby" component={NearbyIncidentsPage} />
            <PrivateRoute path="/detail/:id" component={IncidentDetailPage} />
            <PrivateRoute path="/settings" component={UserSettingPage} />
            <Route path="/entities" component={EntitiesPage} />
            <Route exact path="/privacy-pdf" component={PolicyPDF} />
            <Route exact path="/aviso-legal" component={PolicyPDF} />
            <Route path="/notice-extend/:id" component={NoticeExtendPage} />
            <Route path="/Rules" component={RulesPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/contactCo" component={ContactCoPage} />
            <Route path="/tu-idea-tiene-premio" component={ContestPage} />
            <Route path="/Gecor-Form" component={GecorFormsPage} />
            <Route path="/Gweet-Form" component={GweetFormsPage} />
            <Route path="/contact-us" component={ContactAytoPage} />
            <Route path="/legal-advice" component={LegalAdvicePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/faq-g" component={FaqGecorPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/home" component={HomePage} />
            <Route path="/gweet" component={GweetPage} />
            <Route path="/kit-digital" component={ClickDigitalPage} />
            <Route path="/gecor-blog-home" component={GecorBlogHomePage} />
            <Route path="/politic-environmental" component={PrivatePoliticsPage} />
            <Route path="/company" component={CompanyPage} />
            <Route path="/notice" component={NoticePage} />
            <Route path="/forgot-pass" component={ForgotPassPage} />
            <Route path="/cities" component={CitiesPage} />
            <Route path="/UserDataTcotillasPage" component={UserDataTcotillasPage} />
            <Route path="/faq" component={FaqPage} />
            <Route path="/politica-privacidad-app" component={PrivacyPolicyAppPage} />

            {/******* LA LINEA ***********/}
            <Route path="/incidencias-la-linea" component={IncidentsPageLn} />
            <Route path="/menu-ambiental-la-linea" component={MenuLineaPage} />
            <Route path="/CitizenGuidePage-la-linea" component={CitizenGuidePageLn} />
            <Route path="/local-information-page-la-linea" component={LocalInformationPageLn} />
            <Route path="/contacto-medioambiente-la-linea" component={EnviromentalContactPageLn} />
            <Route path="/educacion-ambiental-la-linea" component={EnviromentalEducationPageLn} />
            <Route path="/enviromental-information-la-linea" component={EnviromentalInformationPageLn} />

            {/******* PUERTO DEL ROSARIO ***********/}
            {/*
            <Route path="/incidencias-puerto-rosario" component={IncidentsPagePr} />
            <Route path="/menu-ambiental-puertorosario" component={MenuPuertoRosarioPage} />
            <Route path="/CitizenGuidePage-pr" component={CitizenGuidePagePr} />
            <Route path="/local-information-page-puertorosario" component={LocalInformationPagePr} />
            <Route path="/contacto-medioambiente-pr" component={EnviromentalContactPagePr} />
            <Route path="/contact-us-PuertoRosario" component={ContactAytoPagePr} />
            <Route path="/enviromental-information-puerto-rosario" component={EnviromentalInformationPagePr} />
            <Route path="/educacion-ambiental-puerto-rosario" component={EnviromentalEducationPagePr} />
            */}

            {/******* CHICLANA ***********/}
            <Route path="/menu-ambiental" component={MenuPage} />
            <Route path="/contact-us-chiclana" component={ContactAytoPageCh} />
            <Route path="/CitizenGuidePage-chiclana" component={CitizenGuidePageCh} />
            <Route path="/faq-chiclana" component={FaqPageCh} />
            <Route path="/local-information-page-chiclana" component={LocalInformationPageCh} />
            <Route path="/contacto-medioambiente-chiclana" component={EnviromentalContactPageCh} />
            <Route path="/enviromental-information-chiclana" component={EnviromentalInformationPageCh} />
            <Route path="/educacion-ambiental-chiclana" component={EnviromentalEducationPageCh} />
            <Route path="/enviromental-information-chiclana" component={EnviromentalInformationPageCh} />

            {/*******VALVERDE***********/}
            <Route path="/Valverde-medio-ambiente" component={MenuValverdePage} />
            <Route path="/valverde-informacion" component={NatureValverdePage} />

            {/*******ROJALES***********/}
            <Route path="/informacion-rojales" component={ResiduosRojalesPage} />
            <Route path="/menu-ambiental-rojales" component={MenuRojalesPage} />

            {/******* TORREMOLINOS ***********/}

            <Route path="/menu-ambiental-torremolinos" component={MenuTorremolinosPage} />
            <Route path="/educacion-ambiental" component={EnviromentalEducationPage} />
            <Route path="/enviromental-information" component={EnviromentalInformationPage} />
            <Route path="/informacion-local-ambiental" component={LocalInformationPage} />
            <Route path="/practicas-medio-ambiente" component={CitizenGuidePage} />

            {/*******ONIL 10438***********/}
            <Route path="/menu-ambiental-onil" component={MenuOnilPage} />
            <Route path="/contacto-medioambiente-onil" component={EnviromentalContactPageOn} />
            <Route path="/CitizenGuidePage-onil" component={CitizenGuidePageOn} />
            <Route path="/local-information-page-onil" component={LocalInformationPageOn} />
            <Route path="/educacion-ambiental-onil" component={EnviromentalEducationPageOn} />
            <Route path="/enviromental-information-onil" component={EnviromentalInformationPageOn} />
            <Route path="/practicas-medio-ambiente-onil" component={CitizenGuidePageOn} />

            {/*******TORRELAVEGA 311***********/}
            <Route path="/menu-ambiental-Torrelavega" component={MenuTorrelavegaPage} />
            <Route path="/contacto-medioambiente-Torrelavega" component={EnviromentalContactPageTv} />
            <Route path="/CitizenGuidePage-Torrelavega" component={CitizenGuidePageTv} />
            <Route path="/local-information-page-Torrelavega" component={LocalInformationPageTv} />
            <Route path="/educacion-ambiental-Torrelavega" component={EnviromentalEducationPageTv} />
            <Route path="/enviromental-information-Torrelavega" component={EnviromentalInformationPageTv} />
            <Route path="/practicas-medio-ambiente-Torrelavega" component={CitizenGuidePageTv} />

            {/*******ALCORCON**********
            <Route path="/alcorcon/Sostenibilidad-Movilidad-Renovables" component={MenuAlcorconPage} />
            <Route path="/alcorcon/informacion-local" component={LocalInformationPageAc} />*/}
            {/*************SOSTENIBILIDAD*******************
            <Route path="/alcorcon/informacion/sostenibilidad" component={SostenibilidadComponent} />
            <Route path="/alcorcon/informacion/sostenibilidad-residuos" component={ResiduosComponent} />
            <Route path="/alcorcon/informacion/sostenibilidad-calidad-aire" component={CalidadAireComponent} />
            <Route path="/alcorcon/informacion/sostenibilidad-biodiversidad" component={BiodiversidadComponent} />
            <Route path="/alcorcon/informacion/sostenibilidad-vertidos-industriales" component={VertidosIndustriales} />
            <Route path="/alcorcon/informacion/sostenibilidad-proteccion-contaminacion-acustica" component={ProteccionContaminacionAcustica} />
            <Route path="/alcorcon/informacion/sostenibilidad-ahorro-energia" component={AhorroEnergiaComponent} />*/}
            {/*************END SOSTENIBILIDAD********************/}
            {/*************MOVILIDAD*******************
            <Route path="/alcorcon/informacion/movilidad" component={MovilidadComponent} />
            <Route path="/alcorcon/informacion/movilidad-aparca-el-coche" component={AlcorconAparCocheComponent} />
            <Route path="/alcorcon/informacion/movilidad-alcorcon-en-bici" component={AlcorconBiciComponent} />
            <Route path="/alcorcon/informacion/movilidad-alcorcon-a-pie" component={AlcorconPieComponent} />
            <Route path="/alcorcon/informacion/movilidad-transporte-publico" component={AlcorconTransPublicoComponent} />
            <Route path="/alcorcon/informacion/movilidad-mas-movilidad" component={MMovilidadComponent} />*/}
            {/*************END MOVILIDAD********************/}
            {/*************RENOVABLES*******************
            <Route path="/alcorcon/informacion/renovables" component={RenovablesComponent} />
            <Route path="/alcorcon/informacion/renovables-ayudas" component={AyudasInstalacionComponent} />
            <Route path="/alcorcon/informacion/renovables-comunidades-energeticas" component={ComEnergeticaLocalComponent} />
            <Route path="/alcorcon/informacion/renovables-energias-renovables" component={EnergiaRenovablesComponent} />*/}
            {/*************END RENOVABLES*******************
            <Route path="/alcorcon/contenido-relacionado" component={ContenidoRelacionadoAc} />
            <Route path="/alcorcon/contacto-medioambiente" component={EnviromentalContactPageAc} />*/}
            {/******END ALCORCON**********/}

            <PrivateRoute path="/interest-points" component={PoisPage} />
            <PrivateRoute path="/interest-points-detail/:vista/:id" component={PoiDetailPage} />
            <PrivateRoute path="/interest-points-map" component={PoisMapPage} />
            <PrivateRoute path="/surveys" component={SurveysPage} />
            <PrivateRoute path="/survey-detail/:id" component={SurveyDetailPage} />
            <Route path="/cookies-policy" component={CookiePage} />
            <Route exact path="/Declaracion-Accesibilidad" component={DeclaracionAccPage} />
            <Route exact path="/security-policy" component={SecurityPolicyPage} />
            {/****************NOTICIAS*********************/}
            <Route exact path="/difusion/:id/:url" component={DifusionDetail} />
            {/****************END NOTICIAS*********************/}
            <Route path="/developers" component={DevelopersPage} />
            <Route path="/deeplink/:url" component={DeepLinkHandler} />
            <Route exact path="/404" component={NotFoundPage} />
            <Route path="*" render={() => (<Redirect to="/404" />)} />
          </Switch>
          {/* Footer v2 */}
          {(() => {
            if (!isUsa) {
              switch (entity && entity.AyuntamientoID) {
                case 268:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_268e
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Medio Ambiente
                  } else {
                    return (
                      <FooterComponent_268
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //Chiclana
                  }

                case 411:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_411e
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Medio Ambiente
                  } else {
                    return (
                      <FooterComponent_411
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //Puerto del Rosario
                  }

                case 143:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_143e
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Medio Ambiente
                  } else {
                    return (
                      <FooterComponent_143
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //Torremolinos
                  }

                case 294:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_294e
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Medio Ambiente
                  } else {
                    return (
                      <FooterComponent_294
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //La Línea
                  }

                case 10411:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_10411
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Valverde Medio ambiente
                  } else {
                    return (
                      <FooterComponent_10411
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //Valverde
                  }

                case 10423:
                  if (urlParams && urlParams.header) {
                    return (
                      <FooterComponent_10423
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    );  //Rojales Medio ambiente
                  } else {
                    return (
                      <FooterComponent_10423
                        {...this.props}
                        isNotFound={isNotFound}
                      />
                    ); //Rojales
                  }

                case 430:
                  return (
                    <FooterComponent_430e
                      {...this.props}
                      isNotFound={isNotFound}
                    />
                  );  //Medio Ambiente
                case 206:
                  return <FooterComponent_206 {...this.props} />; //Granada
                case 10400:
                  return <FooterComponent_10400 {...this.props} />; //Sueca  
                case 429:
                  return <FooterComponent_429 {...this.props} />; //Viladecans
                case 10410:
                  return <FooterComponent_10410 {...this.props} />; //Viladecans
                case 180:
                  return <Footer_180 {...this.props} />; //Las Torres de Cotillas  
                case 405:
                  return <FooterComponent_405 {...this.props} />; //Arroyo de la encomienda
                case 1:
                  return <FooterComponent_1 {...this.props} />; //Arroyo de la encomienda
                case 10438:
                  return <FooterComponent_10438 {...this.props} />; //Onil
                default:
                  return (
                    <FooterComponent_ue
                      {...this.props}
                      isNotFound={isNotFound}
                    />
                  ); //Genérico
              }
            } else {
              return (
                <FooterUsaComponent {...this.props} isNotFound={isNotFound} />
              );
            }
          })()}
          {/* End Footer v2 */}
          <BannerCertificate t={t} page={'global'} />


          {/*
          <Push />
          */}
          <Galletas />
        </React.Fragment>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  const { entities, users } = state;
  const { entity } = entities;

  return {
    users,
    error: "¡Algo ha pasado aqui!",
    entity
  };
};

const tApp = withTranslation()(App);
const connectedApp = connect(mapStateToProps)(tApp);
export { connectedApp as App };
