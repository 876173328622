import { take, put, call, fork } from 'redux-saga/effects';
import { socialLogin } from '../actions';
import { googleClientId } from '../constants';

export const promises = {
  fbLogin: (options) => new Promise((resolve, reject) => {
    window.FB.login((response) => {
      if (response.authResponse) {
        resolve(response.authResponse);
      } else {
        reject(response.status);
      }
    }, options);
  }),
  fbGetMe: (options) => new Promise((resolve) => {
    window.FB.api('/me', options, (me) => resolve(me));
  }),
  loadScript: (src) => new Promise((resolve, reject) => {
    const js = document.createElement('script');
    js.src = src;
    js.onload = resolve;
    js.onerror = reject;
    document.head.appendChild(js);
  }),
  // Función que devuelve una promesa que se resuelve cuando se obtiene el credential de Google
  googleSignIn: () => new Promise((resolve, reject) => {
    // Inicializa Google Identity Services (GIS) solo una vez
    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: (response) => {
        if (response.credential) {
          // Si se obtiene un token de autenticación (credential), lo resolvemos
          resolve(response.credential);
        } else {
          // Si no hay credential, rechazamos la promesa
          reject('Not signed in with the identity provider.');
        }
      }
    });

    // Muestra el prompt para que el usuario inicie sesión
    window.google.accounts.id.prompt();
  })
};

export const appendFbRoot = () => {
  const fbRoot = document.createElement('div');
  fbRoot.id = 'fb-root';
  document.body.appendChild(fbRoot);
};

export const serviceAction = (suffix, service) => (action) =>
  action.type === `SOCIAL_LOGIN_${suffix}` && action.service === service;

export function* loginFacebook({ scope = 'public_profile', fields = 'id,name,email', ...options } = {}) {
  try {
    yield call(promises.fbLogin, { scope, ...options });
    const data = yield call(promises.fbGetMe, { fields });
    const image = `https://graph.facebook.com/${data.id}/picture?type=normal`;
    yield put(socialLogin.success({ ...data, image }));
  } catch (e) {
    yield put(socialLogin.failure(e));
  }
}

export function* prepareFacebook({ appId, version = 'v4.0', ...options }) {
  try {
    yield call(appendFbRoot);
    yield call(promises.loadScript, '//connect.facebook.net/es_ES/sdk.js');
    yield call([window.FB, window.FB.init], { appId, version, ...options });
  } catch (e) {
    yield put(socialLogin.failure(e));
  }
}

export function* watchSocialLoginFacebook() {
  const { options } = yield take(serviceAction('PREPARE', 'facebook'));
  yield call(prepareFacebook, options);
  while (true) {
    const { options } = yield take(serviceAction('REQUEST', 'facebook'));
    yield call(loginFacebook, options);
  }
}

// Función generadora para el login de Google
export function* loginGoogle() {
  try {
    // Llamamos a la función googleSignIn, que iniciará el prompt de Google
    const token = yield call(promises.googleSignIn);

    // Si el token es recibido, obtenemos los detalles del usuario
    const userInfoResponse = yield call(fetch, 'https://www.googleapis.com/oauth2/v3/userinfo', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Verificamos si la solicitud fue exitosa
    if (!userInfoResponse.ok) {
      throw new Error('Failed to fetch user info');
    }

    // Extraemos la información del usuario
    const userData = yield call([userInfoResponse, userInfoResponse.json]);
    const { sub: id, name, email, picture: image } = userData;

    // Dispatch con los datos del usuario
    yield put(socialLogin.success({ id, name, email, image }));

  } catch (e) {
    // Si hay un error, se despacha el error
    yield put(socialLogin.failure(e.message || e));
  }
}

// Función para preparar el login de Google (cargar script)
export function* prepareGoogle({ client_id }) {
  try {
    // Cargamos el script de Google Identity Services
    yield call(promises.loadScript, 'https://accounts.google.com/gsi/client');
  } catch (e) {
    yield put(socialLogin.failure(e));
  }
}

export function* loginGoogleHybrid({token}) {
  try {
    // Si el token es recibido, obtenemos los detalles del usuario
    const userInfoResponse = yield call(fetch, 'https://www.googleapis.com/oauth2/v3/userinfo', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Verificamos si la solicitud fue exitosa
    if (!userInfoResponse.ok) {
      throw new Error('Failed to fetch user info');
    }

    // Extraemos la información del usuario
    const userData = yield call([userInfoResponse, userInfoResponse.json]);
    const { sub: id, name, email, picture: image } = userData;

    // Dispatch con los datos del usuario
    yield put(socialLogin.success({ id, name, email, image }));

  } catch (e) {
    // Si hay un error, se despacha el error
    yield put(socialLogin.failure(e.message || e));
  }
}

// Watcher para escuchar las acciones y llamar a las funciones correspondientes
export function* watchSocialLoginGoogle() {
  // const { options } = yield take(serviceAction('PREPARE', 'google'));
  // yield call(prepareGoogle, options);

  while (true) {
    // const { options } = yield take(serviceAction('REQUEST', 'google'));
    // yield call(loginGoogle, options);
    const { options } = yield take(serviceAction('REQUEST', 'google'));
    yield call(loginGoogleHybrid, options);
  }
}

export default function* () {
  yield fork(watchSocialLoginFacebook);
  yield fork(watchSocialLoginGoogle);
}
